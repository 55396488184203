:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 1.5em 0 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: #266D89;
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.blue {
    background-color:#266D89;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.page {
    min-height:80vh;
}

.page ul {
    list-style-type: none;
}

.page li {
  padding-left: 1.2em;
}

.page li:before {
  content: "\f046"; /* FontAwesome char code inside the '' */
  font-family: FontAwesome; /* FontAwesome or whatever */
  display: inline-block;
  width: 1.2em; /* same as padding-left set on li */
  margin-left: -1.2em; /* same as padding-left set on li */
}
    

/* header
---------------------------------------------------------*/
.klagommal {
    margin-top: 1em;
}
.klagommal h4 {
    margin-bottom:0;
}
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    border-bottom:#266D89 solid 4px;
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:var(--topmenu-bg);
    z-index:100;
    color:white;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin-bottom:0;
}

.slider-caption-outer .container {
    height: 100%;
    position: relative;
    text-align: left;
    display: inline-block;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:-60px;
}

.slick-dots li button:before {
    font-size:0.8rem;
    opacity:1.0;
    color:#fff;
}

.slick-dots li.slick-active button:before {
    color:#f2941c;
}

.slick-dots li {
    border:#D0D0D0 solid 1px;
    border-radius:50%;
    margin:0 2px;
}

.slick-dots li button {
    margin:0;
}

.slider-caption-outer {
    background:rgba(4,71,137,0.2);
    background:rgba(0,0,0,0.5);
}

.slider-caption {
    font-size:1.3rem;
    color:white;
}

.slider-caption h1, .slider-caption h2 {
    color:white;
    font-size:2.0rem;
    margin:0;
}

.slider-caption .button {
    background:black;
    border:#101010 solid 1px;
    color:white;
}

.slider-caption .button:hover {
    background:#f2941c;
    border:#f2941c solid 1px;
    color:white;
}

.start-bildspel .down {
    position:absolute;
    bottom:2vh;
    right:50vw;
    color:#eee;
}

.start-bildspel .down i {
    font-weight:300;
    font-size:5.5rem;
    color:#eee;
}
    

/* Startbox
---------------------------------------------------------*/

.startbox {
    text-decoration:none;
    color:#111;
}

.startbox article {
    margin-bottom:1.5em;
}

.startbox-wrapper {
    position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.startbox-wrapper:before, .startbox-wrapper:after {
    content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:10px;
    bottom:10px;
    left:0;
    right:0;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}

.startbox-wrapper:after {
    right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
    -moz-transform:skew(8deg) rotate(3deg);
    -ms-transform:skew(8deg) rotate(3deg);
    -o-transform:skew(8deg) rotate(3deg);
    transform:skew(8deg) rotate(3deg);
}

.pop-img img {
    width:100%;
    border-bottom:#004D8B solid 4px;
}

.startbox-inner {
    padding:0 1.5em;
    display:block;
    position:relative;
}

.block-content {
    color:#111;
}

.startbox-inner p {
    margin-top:0;
}

.block-rub h2 {
    color:#251408;
    font-size:2.0rem;
    margin:0.5em 0 0 0;
    font-weight:bold;
}

.boxLink {
    padding-bottom:1.5em;
}

/* block2
---------------------------------------------------------*/
section.start-block-wrapper2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {
    text-align:center;
    max-width:850px;
    margin:0 auto;
    width:100%;
}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 .container > h2 {
    text-align:center;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
    color:white;
}

.start-block-wrapper4 h3 {
    color:white;
    font-weight:bold;
}

.start-block-wrapper4 button {
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

/* block5
---------------------------------------------------------*/
section.start-block-wrapper5 {
    padding:0;
}

#kontakt {
    padding:1.5% 0;
    min-height:5vh;
}

#kontakt h2 {
    text-transform:uppercase;
    font-weight:700;
    letter-spacing:-1px;
    color:white;
}

#mc_embed_signup .mc-field-group label {
    color:white;
    }

#mc_embed_signup .button {
    background-color:black !important;
}

.contat-info ul {
    margin:0;
    padding:0;
    list-style:none;
}

.contat-info li:before {
    margin:0;
    padding:0;
    content:"";
    width:auto;
}

.contat-info li {
    padding-left:0;
}



/* Footer
---------------------------------------------------------*/

footer {
    color:white;
    font-size:0.8rem;
}

footer .blue {
    padding:0.5em 0;
}

footer a {
    color: white;
    text-decoration:none;
}

footer a:hover {
    color:#F28D1A;
}

.socialamedia a {
    font-size:2.0rem;
    padding-right:0.5em;
}

footer h4 {
    font-size:1.3rem;
    margin:0.5em 0 0 0;
    color:white;
}

footer span {
    display:block;
}

footer .heding {
    font-size:1.1rem;
}


/* sida
---------------------------------------------------------*/

.top-img {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height:15vh;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);

} 


.contat-info span, .adress span {
    display:block;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #044789;
    clear: both;
}

.downloads-inner {
    padding:1.5em;
}

.downloads-inner ul {
    margin:0;
    padding:0;
}

.downloads-inner li {
    padding-top:0.3em;
    padding-bottom:0.3em;
}

/* other
---------------------------------------------------------*/
.fa-map-marker:before {
    font-size:3.0rem;
    color:#044789;
}

.boltform label {
    display: none;
}

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
    background:#266D89;
    border:#266D89 solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background:#000;
    color:white;
    border:#000 solid 1px;
}

button[type=submit], input[type=submit] {
    background:#266D89;
}

button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

a {
    color:#266D89;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#000;
}

body {
    font-family: OpenSans;
    font-weight: 400;
    color:#282d32;
    font-size:0.9rem;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#251408;
}

h1 {
    font-weight:700;
}

h2 {
    font-size:1.1rem;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {}

@media (max-width: 750px) {}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show {
        background:white;
    }
    .slider-caption-outer {
        background:#044789;
    }
    
    .slider-caption h1, .slider-caption h2 {
        font-size:1.6rem;
    }
    .slider-caption {
        font-size:0.9rem;
    }
    #menu-icon {
        position:absolute;
        top:40px;
        right:10px;
    }
    .menu li a, .menu li li a {
        margin:0;
    }
    .down {
        display:none;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin:0;
    }
    .menu .dropdown ul {
        background:white;
        color:black;
    }
    
}


@media (min-width: var(--breakpoint-menu)) {
    .slider-caption-outer {
        bottom: 0;
        left: 0;
        overflow: visible;
        position: absolute;
        text-align: center;
        right: 0;
        top: 0;
    }
    .slider-caption {
        position: absolute;
        bottom: 45%;
        max-width:50%;
        width: 100%;
        left: 0;
        text-align: left;
        display: block;
        padding: 0;
        z-index: 3;
    }
}
